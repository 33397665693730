const { disableGA, enableGA } = require("./analytics-helpers");
const Cookies = require('js-cookie');

(function init_cookie_consent() {
  const cookieKey = "ddtpCookie";
  var gaAcceptButtons = document.querySelectorAll(".ga-cookies-button-accept");
  var gaRejectButtons = document.querySelectorAll(".ga-cookies-button-reject");

  var acceptedBanner = document.querySelector(".js-cookies-accepted");
  var rejectedBanner = document.querySelector(".js-cookies-rejected");
  var questionBanner = document.querySelector(".js-question-banner");
  var cookieBanner = document.querySelector(".js-cookies-banner");

  var successBaner = document.querySelector(".js-cookies-page-success");

  function showBanner(banner) {
    questionBanner.setAttribute("hidden", "hidden");
    banner.removeAttribute("hidden");

    // Shift focus to the banner
    banner.setAttribute("tabindex", "-1");
    banner.focus();

    banner.addEventListener("blur", function () {
      banner.removeAttribute("tabindex");
    });
  }

  if (Cookies.get(cookieKey)) {
    cookieBanner.setAttribute("hidden", "hidden");
  }

  gaAcceptButtons.forEach((btn) => {
    btn.addEventListener("click", function (event) {
      enableGA();
      Cookies.set(cookieKey, 'accepted', { expires: 360 })              
      showBanner(acceptedBanner);  
      if (successBaner) {
        successBaner.removeAttribute("hidden");
      }      
      event.preventDefault();
    });
  });

  gaRejectButtons.forEach((btn) => {
    btn.addEventListener("click", function (event) {
      disableGA()
      Cookies.set(cookieKey, 'accepted', { expires: 360 })      
      showBanner(rejectedBanner);
      if (successBaner) {
        successBaner.removeAttribute("hidden");
      }      
      event.preventDefault();
    });
  });

  acceptedBanner
    .querySelector(".js-hide")
    .addEventListener("click", function () {
      cookieBanner.setAttribute("hidden", "hidden");
    });

  rejectedBanner
    .querySelector(".js-hide")
    .addEventListener("click", function () {
      cookieBanner.setAttribute("hidden", "hidden");
    });
})()
